import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    contact: {
      dark: "#000",
      main: "#000",
      light: "#000",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: "Montserrat, serif",
    fontWeight: "300",
  },
});

const Layout = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Layout;
