module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de"],"defaultLanguage":"de","siteUrl":"https://example.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/media/nav/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4e9b17854e70376205c6c31bf19124dd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"M3E Communications","short_name":"M3E","start_url":"/","background_color":"#23a6d5","theme_color":"#fff","display":"standalone","icon":"src/media/nav/m3eAgencyLogo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ad6f01bb6fc9d964e19b26f31e5ac343"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
